import { lazy } from 'react';
import { PATH } from '../paths';
import { PackageRoutesConfig } from 'router/models';
import { LAYOUT } from 'common/layouts';

export const mainRoutesConfig: PackageRoutesConfig = {
  routes: [
    {
      path: PATH.MAIN,
      layout: LAYOUT.MAIN,
      component: lazy(() => import('../../features/main/pages/MainPage')),
      exact: true,
    },
    {
      layout: LAYOUT.MAIN,
      path: PATH.BLOG,
      component: lazy(() => import('../../features/blog/pages/BlogPage')),
      exact: true,
    },
    {
      layout: LAYOUT.MAIN,
      path: PATH.BLOG_SINGLE,
      component: lazy(() => import('../../features/blog/pages/BlogSingle')),
      update: 'path',
    },
    {
      path: PATH.COMPANY,
      layout: LAYOUT.MAIN,
      exact: true,
      redirect: PATH.ABOUT_US,
    },
    {
      path: PATH.ABOUT_US,
      layout: LAYOUT.MAIN,
      component: lazy(() => import('../../features/company/pages/AboutUsPage')),
      exact: true,
    },
    {
      layout: LAYOUT.MAIN,
      path: PATH.WHY_DATAART,
      component: lazy(() => import('../../features/company/pages/WhyDataart')),
      exact: true,
    },
    {
      path: PATH.TEAM,
      layout: LAYOUT.MAIN,
      component: lazy(() => import('../../features/company/pages/TeamPage')),
      // component: lazy(() => import('')),
      exact: true,
    },
    {
      layout: LAYOUT.MAIN,
      path: PATH.TEAM_ADVISOR,
      component: lazy(() =>
        import('../../features/company/pages/TeamAdvisorPage')
      ),
      update: 'path',
    },
    {
      layout: LAYOUT.MAIN,
      path: PATH.TEAM_MEMBER,
      component: lazy(() =>
        import('../../features/company/pages/TeamMemberPage')
      ),
      update: 'path',
    },
    {
      layout: LAYOUT.MAIN,
      path: PATH.LOCATIONS,
      component: lazy(() =>
        import('../../features/contact/pages/LocationsPage')
      ),
      exact: true,
    },
    {
      layout: LAYOUT.MAIN,
      path: PATH.LOCATIONS_PRESS_KIT,
      component: lazy(() =>
        import('../../features/contact/pages/PressKitPage')
      ),
      exact: true,
    },
    {
      layout: LAYOUT.MAIN,
      path: PATH.LOCATIONS_SINGLE,
      component: lazy(() =>
        import('../../features/contact/pages/LocationPage')
      ),
      exact: true,
    },
    {
      path: PATH.CLIENTS,
      component: lazy(() => import('../../features/clients/pages/ClientsPage')),
      layout: LAYOUT.MAIN,
      exact: true,
    },
    {
      path: PATH.CLIENT_PAGE,
      component: lazy(() => import('../../features/clients/pages/ClientPage')),
      layout: LAYOUT.MAIN,
      exact: true,
      update: 'path',
    },
    {
      path: PATH.CASE_PAGE,
      component: lazy(() => import('../../features/clients/pages/CasePage')),
      exact: true,
      layout: LAYOUT.MAIN,
      update: 'path',
    },
    {
      layout: LAYOUT.MAIN,
      path: PATH.EVENTS,
      component: lazy(() => import('../../features/events/pages/EventsPage')),
      exact: true,
    },
    {
      layout: LAYOUT.MAIN,
      path: PATH.EVENTS_ARCHIVE,
      component: lazy(() => import('../../features/events/pages/EventsPage')),
      exact: true,
    },
    {
      layout: LAYOUT.MAIN,
      path: PATH.EVENTS_PAGE,
      component: lazy(() => import('../../features/events/pages/EventPage')),
      exact: true,
      update: 'path',
    },
    {
      layout: LAYOUT.MAIN,
      path: PATH.EVENTS_ARCHIVE_PAGE,
      component: lazy(() => import('../../features/events/pages/EventPage')),
      exact: true,
      update: 'path',
    },
    {
      layout: LAYOUT.MAIN,
      path: PATH.SUSTAINABILITY_PAGE,
      component: lazy(() =>
        import('../../features/company/pages/SustainabilityPage')
      ),
      exact: true,
    },
    {
      path: PATH.SERVICES,
      component: lazy(() =>
        import('../../features/services/pages/ServicesPageNew')
      ),
      layout: LAYOUT.MAIN,
      exact: true,
    },
    {
      layout: LAYOUT.MAIN,
      path: PATH.SERVICES_PAGE,
      component: lazy(() =>
        import('../../features/services/pages/SingleService')
      ),
      exact: true,
      update: 'path',
    },
    {
      layout: LAYOUT.MAIN,
      path: PATH.SERVICES_PAGE_INNER,
      component: lazy(() =>
        import('../../features/industries/pages/SingleInnerIndustries')
      ),
      exact: true,
      update: 'path',
    },
    {
      path: PATH.INDUSTRIES,
      component: lazy(() =>
        import('../../features/industries/pages/IndustriesPage')
      ),
      exact: true,
      layout: LAYOUT.MAIN,
    },
    {
      path: PATH.INDUSTRIES_PAGE,
      component: lazy(() =>
        import('../../features/industries/pages/SingleIndustries')
      ),
      exact: true,
      update: 'path',
      layout: LAYOUT.MAIN,
    },
    {
      path: PATH.INDUSTRIES_PAGE_INNER,
      component: lazy(() =>
        import('../../features/industries/pages/SingleInnerIndustries')
      ),
      exact: true,
      update: 'path',
      layout: LAYOUT.MAIN,
    },
    {
      path: PATH.SEARCH_PAGE,
      component: lazy(() => import('../../features/search/pages/SearchPage')),
      exact: true,
      update: 'path',
      layout: LAYOUT.MAIN,
    },
    {
      layout: LAYOUT.MAIN,
      path: PATH.NEWSROOM,
      component: lazy(() =>
        import('../../features/newsroom/pages/NewsRoomPage')
      ),
      exact: true,
    },
    {
      layout: LAYOUT.MAIN,
      path: PATH.ANONYMOUS_PAGE,
      component: lazy(() => import('../../features/company/pages/Anonymous')),
      exact: true,
    },
    {
      layout: LAYOUT.MAIN,
      path: PATH.AWARDS_PAGE,
      component: lazy(() => import('../../features/company/pages/Awards')),
      exact: true,
    },
    {
      layout: LAYOUT.MAIN,
      path: PATH.NEWSROOM_PAGE,
      component: lazy(() =>
        import('../../features/newsroom/pages/NewsRoomSinglePage')
      ),
      exact: true,
      update: 'path',
    },
    {
      layout: LAYOUT.MAIN,
      path: '/500',
      component: lazy(() => import('../../router/components/Global500')),
    },
    {
      layout: LAYOUT.MAIN,
      path: PATH.PLAIN_TEXT,
      component: lazy(() =>
        import('../../features/plainText/pages/PlainTextPage')
      ),
      exact: true,
      update: 'path',
    },
    {
      layout: LAYOUT.MAIN,
      path: '*',
      component: lazy(() => import('../../router/components/Global404')),
    },
  ],
};
