import { DataQueryFunction } from 'network/models';
import { httpClient } from 'network';
import { DataQueryVariables } from 'common/hooks';
import { toPaginationParams } from '../../helpers';
import { clearFalsyProps, clearUrl } from 'common/utils';
import qs from 'qs';

export const queryBlogFeed: DataQueryFunction = (
  variables: DataQueryVariables | undefined
): Promise<any> => {
  const { locale } = variables || {};
  const { industriesIds, tagsIds, contentTypes, mode, name } =
    (variables && variables.filter) || {};
  const paginationParams = toPaginationParams(variables);

  const filterParams = clearFalsyProps({
    industriesIds,
    tagsIds,
    contentTypes,
    mode,
    name,
  });

  return httpClient.get(clearUrl(`/insights/feed`), {
    params: {
      ...filterParams,
      ...paginationParams,
      lang: locale,
    },
    paramsSerializer: params => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });
};
