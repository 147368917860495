import './FilterModal.scss';

import React, { useState, useImperativeHandle, forwardRef } from 'react';

import { cn } from 'ui/utils/bem';
import { Button } from '@dataartdev/uikit/Button';
import { Modal } from '@dataartdev/uikit/Modal';
import { CheckboxGroup } from '@dataartdev/uikit/CheckboxGroup';
import { ChoiceGroup } from '@dataartdev/uikit/ChoiceGroup';
import { CalendarInput } from '@dataartdev/uikit/CalendarInput';
import { Typography } from '@dataartdev/uikit/Typography';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { IconClose } from '@dataartdev/uikit/IconClose';
import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';

import { useDispatch } from 'react-redux';
import { setHeaderInverted } from 'store';

import { enUS, de, ru, uk, bg } from 'date-fns/locale';
import { useIntl } from 'react-intl';
import { RadioGroup } from '@dataartdev/uikit/RadioGroup';
import { IModalFilter } from './models';

interface ModalMethods {
  show: () => void;
  hide: () => void;
}

const locales = {
  en: enUS,
  de: de,
  ru: ru,
  ua: uk,
  bg: bg,
};

const cnFilterModal = cn('FilterModal');

export const FilterModal = forwardRef<ModalMethods, IModalFilter>(
  (props, ref) => {
    const {
      modes,
      industries,
      technologies,
      onSubmitForm,
      onResetForm,
      types,
      datepicker,
      tags,
      contentTypes,
      clearAllLabel = 'Clear All',
      ...otherProps
    } = props;

    const [isModalOpen, setIsModalOpen] = useState(false);

    const dispatch = useDispatch();

    const { locale } = useIntl();

    useImperativeHandle(ref, () => ({
      show() {
        setIsModalOpen(true);
      },
      hide() {
        setIsModalOpen(false);
      },
    }));

    return (
      <Modal
        hasOverlay
        width="full"
        rootClassName={'FilterModalWrapper'}
        onOpen={() => {
          dispatch(setHeaderInverted(true));
          document.body.style.overflow = 'hidden';
        }}
        onClose={() => {
          dispatch(setHeaderInverted(false));
          document.body.style.overflow = 'unset';
        }}
        isOpen={isModalOpen}
        onOverlayClick={(): void => {
          dispatch(setHeaderInverted(false));
          setIsModalOpen(false);
        }}
        visibleHeader
        onEsc={(): void => {
          dispatch(setHeaderInverted(false));
          setIsModalOpen(false);
          document.body.style.overflow = 'unset';
        }}
        {...otherProps}
      >
        <div className={cnFilterModal()}>
          <div className="container">
            <Grid cols="2" yAlign="center" className={cnFilterModal('Head')}>
              <GridItem>
                <Typography.Title size="lg" as="h2">
                  Filter
                </Typography.Title>
              </GridItem>
              <GridItem className={cnFilterModal('Controls-Button')}>
                <Button
                  view="ghost"
                  iconRight={IconClose}
                  inverted
                  onClick={(): void => setIsModalOpen(false)}
                />
              </GridItem>
            </Grid>
            <div className={cnFilterModal('Body')}>
              {modes && (
                <div className={cnFilterModal('Category')}>
                  <Typography.Text
                    className={cnFilterModal('Category-Title')}
                    size="xs"
                    weight="bold"
                    transform="uppercase"
                    as="h2"
                  >
                    {modes?.modesLabel}
                  </Typography.Text>
                  <RadioGroup
                    value={modes?.modeSelected}
                    items={modes?.modes}
                    getLabel={item => item.title}
                    onChange={modes?.modeChange}
                    direction="column"
                  />
                </div>
              )}
              {(types || datepicker) && (
                <div className={cnFilterModal('TypesAndDate')}>
                  {types && (
                    <ChoiceGroup
                      value={types.value}
                      onChange={types.onChange}
                      items={types.contentItems}
                      getLabel={item => item.label}
                      inverted
                    />
                  )}
                  {datepicker && (
                    <CalendarInput
                      value={datepicker.value}
                      placeholder={datepicker.placeholder}
                      onChange={datepicker.onChange}
                      onCancel={datepicker.onCancel}
                      type="date-range"
                      locale={locales[locale]}
                    />
                  )}
                </div>
              )}
              {industries && (
                <div className={cnFilterModal('Category')}>
                  <Typography.Text
                    size="xs"
                    weight="bold"
                    transform="uppercase"
                    className={cnFilterModal('Category-Title')}
                    as="h2"
                  >
                    {industries.title}
                  </Typography.Text>
                  <CheckboxGroup
                    value={industries?.value}
                    items={industries?.contentItems}
                    getLabel={item => item?.title}
                    onChange={industries.onChange}
                    className={cnFilterModal('Category-Industries')}
                    direction="row-wrap"
                    inverted
                  />
                </div>
              )}
              {technologies && (
                <div className={cnFilterModal('Category')}>
                  <Typography.Text
                    size="xs"
                    weight="bold"
                    transform="uppercase"
                    className={cnFilterModal('Category-Title')}
                    as="h2"
                  >
                    {technologies.title}
                  </Typography.Text>
                  <CheckboxGroup
                    value={technologies.value}
                    items={technologies.contentItems}
                    getLabel={item => item.title}
                    onChange={technologies.onChange}
                    className={cnFilterModal('Category-Industries')}
                    direction="row-wrap"
                    inverted
                  />
                </div>
              )}
              {tags && (
                <div className={cnFilterModal('Category')}>
                  <Typography.Text
                    size="xs"
                    weight="bold"
                    transform="uppercase"
                    className={cnFilterModal('Category-Title')}
                    as="h2"
                  >
                    {tags.title}
                  </Typography.Text>
                  <CheckboxGroup
                    value={tags.value}
                    items={tags.contentItems}
                    getLabel={item => item.title}
                    onChange={tags.onChange}
                    className={cnFilterModal('Category-Tags')}
                    direction="row-wrap"
                    inverted
                  />
                </div>
              )}
              {contentTypes && (
                <div className={cnFilterModal('Category')}>
                  <Typography.Text
                    size="xs"
                    weight="bold"
                    transform="uppercase"
                    className={cnFilterModal('Category-Title')}
                    as="h2"
                  >
                    {contentTypes.title}
                  </Typography.Text>
                  <CheckboxGroup
                    value={contentTypes.value}
                    items={contentTypes.contentItems}
                    getLabel={item => item.title}
                    onChange={contentTypes.onChange}
                    className={cnFilterModal('Category-Tags')}
                    direction="row-wrap"
                    inverted
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={cnFilterModal('Bottom')}>
          <Button
            view="secondary"
            iconRight={IconArrowRight}
            label={'Submit'}
            width="full"
            onClick={onSubmitForm}
            align="center"
          />
          <Button
            view="transparent"
            label={clearAllLabel}
            inverted
            onClick={onResetForm}
          />
        </div>
      </Modal>
    );
  }
);
