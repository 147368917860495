import { useState, useEffect } from 'react';
import { DataQueryFunction } from 'network';
import { DataQueryOptions, QueryFilter } from './models';
import { AxiosError, AxiosResponse } from 'axios';

interface PaginationData {
  page?: {
    totalPages: number;
  };
}

interface DataQueryResult<T> {
  response: AxiosResponse<T> | null;
  data: T | null;
  loading: boolean;
  error: AxiosError | null;
  pagination: {
    page: number;
    totalPages?: number;
  };
  totals: number;
  filter: QueryFilter;
  refetch: () => void;
  updateData: (data: T) => void;
  updateFilter: (newFilter: QueryFilter) => void;
}

export const useDataQuery = <T>(
  query: DataQueryFunction | null,
  variables: object | null = null,
  options?: DataQueryOptions
): DataQueryResult<T> => {
  const { filter: varFilter, pagination: varPagination }: any = variables || {};
  const { page: varPage } = varPagination || {};
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState<AxiosResponse<T> | null>(null);
  const [error, setError] = useState<AxiosError | null>(null);
  const [params, setParams] = useState<{
    pagination: {
      page: number;
    };
    filter: QueryFilter;
  }>({
    pagination: {
      page: varPage || 1,
    },
    filter: varFilter || {},
  });

  const { skip = null } = options || {};

  const fetchData = () => {
    if (skip || !query) {
      setLoading(false);
      return;
    }
    setLoading(true);
    query({
      ...variables,
      ...params,
    })
      .then((res: AxiosResponse<T>) => {
        setResponse(res);
        setLoading(false);
      })
      .catch(err => {
        setError(err);
        setLoading(false);
      });
  };

  const refetch = () => {
    if (!loading) fetchData();
  };

  const updateFilter = (newFilter: QueryFilter) => {
    setParams(state => ({
      pagination: {
        ...state.pagination,
        page: 1,
      },
      filter: { ...state.filter, ...newFilter },
    }));

    setLoading(true);
  };

  // const updateData = (data: T) => {
  //   setResponse((res) => ({ ...res, data }));
  // };

  const updateData = (data: T) => {
    setResponse(res =>
      res ? { ...res, data: { ...res.data, ...data } } : null
    );
  };

  useEffect(() => {
    fetchData();
  }, [query, skip, params]);

  return {
    response,
    data: (response && response.data) || null,
    pagination: params.pagination,
    totals:
      (response && (response.data as PaginationData))?.page?.totalPages || 0,
    loading,
    error,
    filter: params.filter,
    refetch,
    updateData,
    updateFilter,
  };
};
