import './CaseCard.scss';
import React, { HTMLAttributes } from 'react';
import { NavLink } from 'react-router-dom';
import { usePropsHandler } from '../EventInterceptor/usePropsHandler';
import { cn } from '../../utils/bem';
import { IconProps } from '@dataartdev/uikit/Icon';
import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';
import { Button } from '@dataartdev/uikit/Button';
import { Picture } from '@dataartdev/uikit/Picture';
import { Typography } from '@dataartdev/uikit/Typography';
import { Logo } from '../../../features/industries/pages/models';

export interface Props extends Omit<HTMLAttributes<HTMLDivElement>, 'id'> {
  id: number;
  routePath?: string;
  buttonTitle?: string;
  hasClient?: boolean;
  buttonIcon?: React.FC<IconProps>;
  color?: string;
  logo?: Logo;
  shortDescription?: string;
  title?: string;
  size?: string;
  subtitle?: string;
  showTitle?: boolean;
  showButton?: boolean;
}

export const cnCaseCard = cn('CaseCard');

export const CaseCard: React.FC<Props> = props => {
  const {
    color,
    hasClient,
    logo,
    routePath,
    shortDescription,
    title,
    buttonTitle,
    buttonIcon = IconArrowRight,
    size,
    subtitle,
    showTitle = false,
    showButton,
  } = usePropsHandler(cnCaseCard(), props);

  return (
    <div
      className={cnCaseCard({
        main: !hasClient,
        border: !!color && !hasClient,
        size: size,
      })}
    >
      {!hasClient && color && (
        <div
          className={cnCaseCard('Border')}
          style={{ backgroundColor: color }}
        />
      )}

      {routePath && routePath.includes('http') && (
        <NavLink
          to={{ pathname: routePath }}
          target="_blank"
          className={cnCaseCard('OverlayLink')}
          aria-label={title}
        />
      )}

      {routePath && !routePath.includes('http') && (
        <NavLink
          to={routePath}
          className={cnCaseCard('OverlayLink')}
          aria-label={title}
        />
      )}

      {subtitle && (
        <Typography.Text
          className={cnCaseCard('SubTitle')}
          size="xs"
          weight="bold"
          transform="uppercase"
        >
          {subtitle}
        </Typography.Text>
      )}

      {(!hasClient || showTitle) && (
        <Typography.Title className={cnCaseCard('Title')} size="sm" as="h3">
          {title}
        </Typography.Title>
      )}

      {hasClient && !!logo && Object.keys(logo).length > 0 && (
        <Picture
          className={cnCaseCard('Logo')}
          desktop={logo.url}
          desktopWebp={logo.urlWebp}
        />
      )}

      {shortDescription && (
        <Typography.Text className={cnCaseCard('Text')}>
          {shortDescription}
        </Typography.Text>
      )}

      {(routePath || showButton) && (
        <div className={cnCaseCard('Bottom')}>
          <Button
            view="link-secondary"
            iconSize={'m'}
            label={buttonTitle}
            iconRight={buttonIcon}
            isLink={!!routePath}
            to={routePath}
          />

          {!hasClient && !!logo && Object.keys(logo).length > 0 && (
            <Picture
              className={cnCaseCard('Logo')}
              desktop={logo.url}
              desktopWebp={logo.urlWebp}
            />
          )}
        </div>
      )}
    </div>
  );
};
