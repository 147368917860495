import { DataQueryFunction } from 'network/models';
import { httpClient } from 'network';
import { DataQueryVariables } from 'common/hooks';
import qs from 'qs';
import { toPaginationParams } from 'network/helpers';
import { clearUrl, clearFalsyProps } from 'common/utils';

export const queryEventsPage: DataQueryFunction = (
  variables: DataQueryVariables | undefined
): Promise<any> => {
  const { locale } = variables || {};
  const filter = (variables && variables.filter) || {};
  const paginationParams = toPaginationParams(variables);

  let filterParams = clearFalsyProps(filter);

  if (typeof filterParams.industriesIds === 'string') {
    filterParams.industriesIds = [Number(filterParams.industriesIds)];
  }

  if (typeof filterParams.technologiesIds === 'string') {
    filterParams.technologiesIds = [Number(filterParams.technologiesIds)];
  }

  return httpClient.get(clearUrl(`/events`), {
    params: {
      ...filterParams,
      ...paginationParams,
      lang: locale,
    },
    paramsSerializer: params => {
      return qs.stringify(params);
    },
  });
};
