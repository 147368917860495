/* eslint-disable jsx-a11y/control-has-associated-label */
// @ts-nocheck
/* eslint-disable jsx-a11y/anchor-has-content */
import './BlogCard.scss';

import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';

import { usePropsHandler } from '../EventInterceptor/usePropsHandler';
import { forwardRefWithAs } from '../../utils/types/PropsWithAsAttributes';
import { cn } from '../../utils/bem';

import { getSizeByMap } from '@dataartdev/uikit/__internal__/cjs-src/utils/getSizeByMap';
import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';
import { Button } from '@dataartdev/uikit/Button';
import { Picture } from '@dataartdev/uikit/Picture';
import { Tag } from '@dataartdev/uikit/Tag';
import * as Typography from '@dataartdev/uikit/Typography';

export const cardPropSize = ['s', 'm', 'l'] as const;
export type CardPropSize = typeof cardPropSize[number];
export const cardPropSizeDefault: CardPropSize = cardPropSize[0];

export const cardPropView = [
  'feed',
  'filled',
  'text',
  'text2',
  'text3',
] as const;

export type CardPropView = typeof cardPropView[number];
export const cardPropViewDefault: CardPropView = cardPropView[0];

export const cardPropDirection = ['column', 'row'] as const;
export type CardPropDirection = typeof cardPropDirection[number];
export const cardPropSizeDirection: CardPropDirection = cardPropDirection[0];

export type Props = {
  size?: CardPropSize;
  view?: CardPropView;
  direction?: CardPropDirection;
};

const sizeMap: Record<Props, Typography.TitleProps> = {
  s: 'tiny',
  m: 'sm',
  l: 'md',
};

export const cnBlogCard = cn('BlogCard');

export const BlogCard = forwardRefWithAs<Props>((props, ref) => {
  const {
    view = cardPropViewDefault,
    direction = cardPropSizeDirection,
    size = cardPropSizeDefault,
    ...data
  } = usePropsHandler(cnBlogCard(), props);

  const {
    postDate,
    hrvTags,
    tags: tagsList,
    title,
    picture,
    linkIcon = IconArrowRight,
    onClickButton,
    url,
    path,
    descriptionSmall,
    iconSize = 'm',
    style,
    linkTitle,
    detailLinkTitle,
    type,
  } = data || {};

  const currentIcon = linkIcon;

  const tags = useMemo(
    () => (
      <>
        <div className={cnBlogCard('Tags')}>
          {hrvTags && (
            <>
              {hrvTags.map(tag => (
                <div key={tag.id} className={cnBlogCard('Tag')}>
                  <Tag mode="link" label={tag.title} size="s" />
                </div>
              ))}
            </>
          )}
          {tagsList && (
            <>
              {tagsList.map(tag => (
                <div key={tag.id} className={cnBlogCard('Tag')}>
                  <Tag mode="link" label={tag.title} size="s" />
                </div>
              ))}
            </>
          )}
        </div>
      </>
    ),
    [hrvTags, tagsList]
  );

  const onClickButtonHandler = () => {
    if (url && isExternalTransition) {
      window.open(url, '_blank');
    }
  };

  return (
    <div
      className={cnBlogCard({
        view,
        size,
        direction,
      })}
      style={style}
    >
      {path?.includes('http') ? (
        <a className={cnBlogCard('Link')} href={path} target="_blank" />
      ) : (
        <NavLink className={cnBlogCard('Link')} to={path} aria-label={title} />
      )}
      {view !== 'text' && view !== 'text2' && view !== 'text3' && picture && (
        <div className={cnBlogCard('Photo')}>
          <Picture {...picture} />
        </div>
      )}

      <div className={cnBlogCard('Body')}>
        {view !== 'text' && view !== 'text3' && postDate && (
          <div className={cnBlogCard('Date')}>
            <Typography.Typography.Text weight="semibold" size="xs">
              {postDate}
            </Typography.Typography.Text>
          </div>
        )}

        {view !== 'text' && view !== 'text3' && type && (
          <div className={cnBlogCard('Date')}>
            <Typography.Typography.Text
              weight="semibold"
              size="xs"
              transform="uppercase"
            >
              {type.title}
            </Typography.Typography.Text>
          </div>
        )}

        {descriptionSmall && (
          <div className={cnBlogCard('DescriptionSmall')}>
            <Typography.Typography.Text
              weight="semibold"
              size="xs"
              transform="uppercase"
            >
              {descriptionSmall}
            </Typography.Typography.Text>
          </div>
        )}

        {view === 'text3' && tags}

        <div className={cnBlogCard('Title')}>
          <Typography.Typography.Title
            weight="bold"
            size={getSizeByMap(sizeMap, size)}
            as="h3"
          >
            {title}
          </Typography.Typography.Title>
        </div>

        {view !== 'text' && view !== 'filled' && view !== 'text3' && tags}

        {view !== 'text' &&
          view !== 'text2' &&
          view !== 'feed' &&
          // size !== 's' &&
          (linkTitle || detailLinkTitle) && (
            <div
              style={{
                pointerEvents: 'none',
              }}
              className={cnBlogCard('Button')}
            >
              <Button
                view="link-secondary"
                iconRight={currentIcon}
                iconSize={iconSize}
                label={detailLinkTitle ?? linkTitle}
                onClick={onClickButton || onClickButtonHandler}
              />
            </div>
          )}
      </div>
    </div>
  );
});
