export interface RoutePaths {
  MAIN: string;
  BLOG: string;
  BLOG_SINGLE: string;
  FAQ: string;
  TEAM: string;
  TEAM_ADVISOR: string;
  TEAM_MEMBER: string;
  COMPANY: string;
  ABOUT_US: string;
  WHY_DATAART: string;
  LOCATIONS: string;
  LOCATIONS_PRESS_KIT: string;
  LOCATIONS_SINGLE: string;
  CLIENTS: string;
  CLIENT_PAGE: string;
  CASE_PAGE: string;
  EVENTS: string;
  EVENTS_ARCHIVE: string;
  EVENTS_PAGE: string;
  EVENTS_ARCHIVE_PAGE: string;
  LIFE_PAGE: string;
  INTERNSHIP_PAGE: string;
  SUSTAINABILITY_PAGE: string;
  AWARDS_PAGE: string;
  ANONYMOUS_PAGE: string;
  SERVICES: string;
  SERVICES_PAGE: string;
  SERVICES_PAGE_INNER: string;
  SERVICES_PAGE_INNER_SECONDARY: string;
  INDUSTRIES: string;
  INDUSTRIES_PAGE: string;
  INDUSTRIES_PAGE_INNER: string;
  SEARCH_PAGE: string;
  NEWSROOM: string;
  NEWSROOM_PAGE: string;
  PLAIN_TEXT: string;
}

export const PATH: RoutePaths = {
  MAIN: '/:lang([a-zA-Z]{2})?',
  BLOG: '/:lang([a-zA-Z]{2})?/blog',
  BLOG_SINGLE: '/:lang([a-zA-Z]{2})?/blog/:slug',
  FAQ: '/:lang([a-zA-Z]{2})?/faq',
  TEAM: '/:lang([a-zA-Z]{2})?/company/team/',
  TEAM_ADVISOR: '/:lang([a-zA-Z]{2})?/company/team/advisor/:slug',
  TEAM_MEMBER: '/:lang([a-zA-Z]{2})?/company/team/:slug',
  COMPANY: '/:lang([a-zA-Z]{2})?/company',
  ABOUT_US: '/:lang([a-zA-Z]{2})?/company/about-us',
  WHY_DATAART: '/:lang([a-zA-Z]{2})?/company/why-dataart',
  LOCATIONS: '/:lang([a-zA-Z]{2})?/contact-us',
  LOCATIONS_PRESS_KIT: '/:lang([a-zA-Z]{2})?/contact-us/press-kit',
  LOCATIONS_SINGLE: '/:lang([a-zA-Z]{2})?/contact-us/:slug',

  CLIENTS: '/:lang([a-zA-Z]{2})?/clients',
  CLIENT_PAGE: '/:lang([a-zA-Z]{2})?/clients/:slug',
  CASE_PAGE: '/:lang([a-zA-Z]{2})?/clients/case-studies/:slug',
  EVENTS: '/:lang([a-zA-Z]{2})?/company/events/',
  EVENTS_ARCHIVE: '/:lang([a-zA-Z]{2})?/company/events/archive',
  EVENTS_PAGE: '/:lang([a-zA-Z]{2})?/company/events/:slug',
  EVENTS_ARCHIVE_PAGE: '/:lang([a-zA-Z]{2})?/company/events/archive/:slug',
  LIFE_PAGE: '/:lang([a-zA-Z]{2})?/life',
  INTERNSHIP_PAGE: '/:lang([a-zA-Z]{2})?/internship',
  SUSTAINABILITY_PAGE: '/:lang([a-zA-Z]{2})?/company/sustainability/',
  AWARDS_PAGE: '/:lang([a-zA-Z]{2})?/company/awards/',

  ANONYMOUS_PAGE: '/:lang([a-zA-Z]{2})?/anonymous-report',

  SERVICES: '/:lang([a-zA-Z]{2})?/services',
  SERVICES_PAGE: '/:lang([a-zA-Z]{2})?/services/:slug',
  SERVICES_PAGE_INNER: '/:lang([a-zA-Z]{2})?/services/:slug/:inner_page',
  SERVICES_PAGE_INNER_SECONDARY:
    '/:lang([a-zA-Z]{2})?/services/:slug/:inner_page/:inner_page_secondary',

  INDUSTRIES: '/:lang([a-zA-Z]{2})?/industries',
  INDUSTRIES_PAGE: '/:lang([a-zA-Z]{2})?/industries/:slug/',
  INDUSTRIES_PAGE_INNER: '/:lang([a-zA-Z]{2})?/industries/:slug/:inner_page',

  SEARCH_PAGE: '/:lang([a-zA-Z]{2})?/search-page',

  NEWSROOM: '/:lang([a-zA-Z]{2})?/company/newsroom',
  NEWSROOM_PAGE: '/:lang([a-zA-Z]{2})?/company/newsroom/:parentSlug/:slug',
  PLAIN_TEXT: '/:lang([a-zA-Z]{2})?/:slug?/:slug_2?/:slug_3?',
};
