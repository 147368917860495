import { DataQueryFunction } from 'network/models';
import { httpClient } from 'network';
import { DataQueryVariables } from 'common/hooks';
import { clearUrl } from 'common/utils';

export const queryAwards: DataQueryFunction = (
  variables: DataQueryVariables | undefined
): Promise<any> => {
  const { locale } = variables || {};
  return httpClient.get(clearUrl(`/pages/awards`), {
    params: {
      lang: locale,
    },
  });
};
