import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { stringify } from 'query-string';
import { ITags } from './models';
import { ISearchPageData } from '../../../features/search/pages/models';

// eslint-disable-next-line react-hooks/rules-of-hooks
const BACKEND_API = process.env.REACT_APP_BACKEND_API;
declare const window: any;

export const querySearchPage = createApi({
  reducerPath: 'searchPageApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BACKEND_API,
  }),
  endpoints: build => ({
    searchPageData: build.query<any, string>({
      query: (search: string) => ({
        url: `pages/search/${search}`,
        responseType: 'json',
        paramsSerializer: (params: any) => {
          return stringify(params);
        },
        // withCredentials: true,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Accept-Language': localStorage.getItem('currentLang')
            ? window.INITIAL_STATE.languages.find(
                (w: { slug: string | null }) =>
                  w.slug === localStorage.getItem('currentLang')
              )?.iso
            : window.INITIAL_STATE.detectedLanguage.iso,
        },
        // credentials: 'include',
        // auth: {
        //   username: 'web-api-login',
        //   password: 'vqYh2WV37vjKDMG8',
        // },
      }),
    }),
    getAllTags: build.query<ITags[], void>({
      query: () => {
        return {
          url: 'pages/search/tags',
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Accept-Language': localStorage.getItem('currentLang')
              ? window.INITIAL_STATE.languages.find(
                  (w: { slug: string | null }) =>
                    w.slug === localStorage.getItem('currentLang')
                )?.iso
              : window.INITIAL_STATE.detectedLanguage.iso,
          },
        };
      },
    }),
    getCurrentTagData: build.query<ISearchPageData, string>({
      query: (search: string) => {
        // const tagsQueryArr = search.map((tag: ITag) => `tags=${tag.value}`);
        // const tagsQuery = tagsQueryArr.join('&');
        const serchParam = search.substring(search.indexOf('=') + 1);
        return {
          url: `pages/search`,
          params: {
            tags: serchParam,
          },
          responseType: 'json',
          paramsSerializer: (params: any) => {
            return stringify(params);
          },
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Accept-Language': localStorage.getItem('currentLang')
              ? window.INITIAL_STATE.languages.find(
                  (w: { slug: string | null }) =>
                    w.slug === localStorage.getItem('currentLang')
                )?.iso
              : window.INITIAL_STATE.detectedLanguage.iso,
          },
        };
      },
    }),
    getNameData: build.query<ISearchPageData, any>({
      query: (search: string) => {
        const serchParam = search.substring(search.indexOf('=') + 1);
        return {
          url: `pages/search?name=${serchParam.trim().replaceAll(' ', '%20')}`,
          // params: {
          //   name: serchParam,
          // },
          responseType: 'json',
          paramsSerializer: (params: any) => {
            return stringify(params);
          },
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Accept-Language': localStorage.getItem('currentLang')
              ? window.INITIAL_STATE.languages.find(
                  (w: { slug: string | null }) =>
                    w.slug === localStorage.getItem('currentLang')
                )?.iso
              : window.INITIAL_STATE.detectedLanguage.iso,
          },
        };
      },
    }),
  }),
});

export const {
  useSearchPageDataQuery,
  useLazySearchPageDataQuery,
  useGetAllTagsQuery,
  useLazyGetAllTagsQuery,
  useGetCurrentTagDataQuery,
  useLazyGetCurrentTagDataQuery,
  useGetNameDataQuery,
  useLazyGetNameDataQuery,
} = querySearchPage;
