import './ClientCard.scss';

import React from 'react';
import { NavLink } from 'react-router-dom';
import { cn } from '../../utils/bem';

import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';
import { Button } from '@dataartdev/uikit/Button';
import { Picture } from '@dataartdev/uikit/Picture';
import { Typography } from '@dataartdev/uikit/Typography';
import { prepareButtonAttrs } from '../../utils/prepareButtonAttrs';
import { Client } from '../ContentBuilder/templates/models/CTI_68';

export const cnClientCard = cn('ClientCard');

export const ClientCard: React.FC<Client> = props => {
  const { link, picture, isPageHidden, shortDescription } = props || {};

  const { link: routePath, title: linkTitle, targetBlank } = link || {};
  const buttonAttr = prepareButtonAttrs(routePath, linkTitle, targetBlank);

  return (
    <div className={cnClientCard({ isPageHidden })}>
      {routePath && !routePath.includes('http') && !isPageHidden ? (
        <NavLink to={routePath} className={cnClientCard('OverlayLink')} />
      ) : (
        <a
          target="_blank"
          href={routePath}
          className={cnClientCard('OverlayLink')}
        />
      )}
      <div className={cnClientCard('Photo')}>
        {picture && <Picture {...picture} />}
      </div>
      {shortDescription && (
        <div className={cnClientCard('Inner')}>
          <div className={cnClientCard('Text')}>
            <Typography.Text size="m">{shortDescription}</Typography.Text>
          </div>
          <div className={cnClientCard('Button')}>
            {Object.keys(buttonAttr)?.length > 0 && (
              <Button
                view="link-secondary"
                color="primary"
                iconRight={IconArrowRight}
                iconSize="m"
                {...buttonAttr}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};
