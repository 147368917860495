type ObjectWithProps = { [key: string]: any };

export function clearFalsyProps(object: ObjectWithProps): ObjectWithProps {
  return Object.keys(object).reduce((acc: ObjectWithProps, key) => {
    if (
      object[key] !== null &&
      object[key] !== undefined &&
      object[key] !== ''
    ) {
      acc[key] = object[key];
    }
    return acc;
  }, {});
}
