// @ts-nocheck
import './FileCardForm.scss';

import React, { useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import * as yup from 'yup';
import { Message } from 'yup/lib/types';

import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';
import { cn } from 'ui/utils/bem';
import { Button } from '@dataartdev/uikit/Button';
import { Checkbox } from '@dataartdev/uikit/Checkbox';
import { Form, FormBody } from '../Form/Form';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { TextField } from '@dataartdev/uikit/TextField';

yup.addMethod(yup.string, 'integer', function() {
  return this.matches(/^\d+$/, 'The field should have digits only');
});

export const schemaLetsTalk = (
  phoneError: Message<{}>,
  emailError: Message<{ regex: RegExp }> | undefined,
  requiredError: Message<{}> | undefined
) => [
  yup.object({
    email: yup
      .string()
      .email(emailError)
      .required(requiredError)
      .nullable(),
    privacyAgreement: yup
      .boolean()
      .oneOf([true], 'on')
      .required(),
  }),

  yup.object({
    name: yup
      .string()
      .min(3)
      .required()
      .nullable(),
    email: yup
      .string()
      .email(emailError)
      .required(requiredError)
      .nullable(),
    privacyAgreement: yup
      .boolean()
      .oneOf([true], 'on')
      .required(),
  }),

  yup.object({
    name: yup
      .string()
      .min(3)
      .required()
      .nullable(),
    email: yup
      .string()
      .min(3)
      .email(emailError)
      .required(requiredError)
      .nullable(),
    phone: yup
      .string()
      .nullable()
      .integer()
      .min(6)
      .max(20)
      .required(),
    company: yup
      .string()
      .min(3)
      .nullable(),
    additionalInfo: yup
      .string()
      .min(3)
      .nullable(),
    privacyAgreement: yup
      .boolean()
      .oneOf([true], 'on')
      .required(),
  }),

  yup.object({
    name: yup
      .string()
      .min(3)
      .required()
      .nullable(),
    email: yup
      .string()
      .email(emailError)
      .required(requiredError)
      .nullable(),
    company: yup
      .string()
      .min(3)
      .nullable(),
    privacyAgreement: yup
      .boolean()
      .oneOf([true], 'on')
      .required(),
  }),
];

type Props = {
  title?: string;
  extension?: string;
  date?: string;
  size?: any;
  url?: string;
};

const cnFileCardForm = cn('FileCardForm');

export const FileCardForm: React.FC<Props> = (
  { onSubmit, type = 0, url, dictionaries, recaptchaKey },
  ref
) => {
  const initialValues = {
    name: '',
    phone: '',
    company: '',
    email: '',
    additionalInfo: '',
    privacyAgreement: false,
  };

  const reCaptchaRef = useRef(null);
  const schema = schemaLetsTalk();

  return (
    <>
      <Form
        enableReinitialize
        initialValues={initialValues}
        validationSchema={schema[type]}
        onSubmit={async (values, actions) => {
          let token = null;
          if (recaptchaKey) token = await reCaptchaRef.current.executeAsync();
          onSubmit({ ...values, token: token }, actions);
        }}
      >
        {({
          isSubmitting,
          errors,
          handleChange,
          handleBlur,
          values,
          touched,
          dirty,
        }) => {
          return (
            <FormBody className={cnFileCardForm({ type })}>
              <div className={cnFileCardForm('Inner')}>
                <div className={cnFileCardForm('Body')}>
                  <Grid
                    breakpoints={{
                      desktop: { cols: type === 2 ? 2 : 1, gap: 'xl' },
                      tablet: { cols: 1, gap: 'xl' },
                      mobile: { cols: 1, gap: 'xl' },
                    }}
                  >
                    {(type === 1 || type === 2 || type === 3) && (
                      <GridItem>
                        <TextField
                          name="name"
                          required
                          onChange={({ e }) => handleChange(e)}
                          onBlur={handleBlur}
                          value={values.name}
                          label="Name"
                          view="outline"
                          width="full"
                          caption={touched.name && errors.name}
                          status={touched.name && !!errors.name && 'alert'}
                        />
                      </GridItem>
                    )}
                    {type === 2 && (
                      <>
                        <GridItem>
                          <TextField
                            name="company"
                            onChange={({ e }) => handleChange(e)}
                            onBlur={handleBlur}
                            value={values.company}
                            label={
                              dictionaries.companySiteFormDownloadFile
                                .companySiteFormDownloadFileCompany
                            }
                            view="outline"
                            width="full"
                            type="tel"
                            caption={touched.company && errors.company}
                            status={
                              touched.company && !!errors.company && 'alert'
                            }
                          />
                        </GridItem>
                        <GridItem>
                          <TextField
                            name="phone"
                            onChange={({ e }) => handleChange(e)}
                            onBlur={handleBlur}
                            value={values.phone}
                            label={
                              dictionaries.companySiteFormDownloadFile
                                .companySiteFormDownloadFilePhone
                            }
                            view="outline"
                            width="full"
                            type="tel"
                            required
                            caption={touched.phone && errors.phone}
                            status={touched.phone && !!errors.phone && 'alert'}
                          />
                        </GridItem>
                      </>
                    )}
                    <GridItem>
                      <TextField
                        name="email"
                        onChange={({ e }) => handleChange(e)}
                        onBlur={handleBlur}
                        value={values.email}
                        label={
                          dictionaries.companySiteFormDownloadFile
                            .companySiteFormDownloadFileEmail
                        }
                        view="outline"
                        width="full"
                        required
                        caption={touched.email && errors.email}
                        status={touched.email && !!errors.email && 'alert'}
                      />
                    </GridItem>
                    {type === 3 && (
                      <GridItem>
                        <TextField
                          name="company"
                          onChange={({ e }) => handleChange(e)}
                          onBlur={handleBlur}
                          value={values.company}
                          label={
                            dictionaries.companySiteFormDownloadFile
                              .companySiteFormDownloadFileCompany
                          }
                          view="outline"
                          width="full"
                          type="tel"
                          caption={touched.company && errors.company}
                          status={
                            touched.company && !!errors.company && 'alert'
                          }
                        />
                      </GridItem>
                    )}
                    {type === 2 && (
                      <GridItem>
                        <TextField
                          name="additionalInfo"
                          onChange={({ e }) => handleChange(e)}
                          onBlur={handleBlur}
                          value={values.additionalInfo}
                          label={
                            dictionaries.companySiteFormDownloadFile
                              .companySiteFormDownloadFileAddInfo
                          }
                          view="outline"
                          width="full"
                          caption={
                            touched.additionalInfo && errors.additionalInfo
                          }
                          status={
                            touched.additionalInfo &&
                            !!errors.additionalInfo &&
                            'alert'
                          }
                        />
                      </GridItem>
                    )}
                    <GridItem className={cnFileCardForm('Buttons')}>
                      <div className={cnFileCardForm('Bottom')}>
                        <Checkbox
                          required
                          label={
                            dictionaries.companySiteFormDownloadFile
                              .companySiteFormDownloadFileSubscribePolicy
                          }
                          name="privacyAgreement"
                          onChange={({ e }) => handleChange(e)}
                          onBlur={handleBlur}
                          checked={values.privacyAgreement}
                        />
                        <Button
                          iconRight={IconArrowRight}
                          disabled={
                            isSubmitting ||
                            !dirty ||
                            !!Object.keys(errors).length
                          }
                          label={
                            dictionaries.companySiteFormDownloadFile
                              .companySiteFormDownloadFileSubmit
                          }
                          type="submit"
                        />
                      </div>
                    </GridItem>
                  </Grid>
                </div>

                {recaptchaKey && (
                  <ReCAPTCHA
                    ref={reCaptchaRef}
                    sitekey={recaptchaKey}
                    size="invisible"
                  />
                )}
              </div>
            </FormBody>
          );
        }}
      </Form>
    </>
  );
};
