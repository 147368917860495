import { DataQueryFunction } from 'network/models';
import { httpClient } from 'network';
import { DataQueryVariables } from 'common/hooks';
import { clearUrl } from 'common/utils';

export const querySingleIndustries: DataQueryFunction = (
  variables: DataQueryVariables | undefined
): Promise<any> => {
  const { slug, locale } = variables || {};

  return httpClient.get(clearUrl(`/industries/${slug}`), {
    params: {
      lang: locale,
    },
  });
};
