// @ts-nocheck
import './HeaderMenuMobile.scss';

import React, { useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';

import { PropsWithJsxAttributes } from '../../../utils/types/PropsWithJsxAttributes';
import { cn } from '../../../utils/bem';
import { useChoiceGroup } from '@dataartdev/uikit/useChoiceGroup';
import { IconArrowDown } from '@dataartdev/uikit/IconArrowDown';
import { IconOpenInNew } from '@dataartdev/uikit/IconOpenInNew';
import { Button } from '@dataartdev/uikit/Button';
import { Typography } from '@dataartdev/uikit/Typography';
import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';
import TagManager from 'react-gtm-module';

export const cnHeaderMenuMobile = cn('HeaderMenuMobile');

export type Item = {
  title?: string;
  nodeUrl?: string;
  target?: string;
  externalLink?: boolean;
  onClick?: React.EventHandler<React.MouseEvent>;
  children?: never;
};

type MenuProps = PropsWithJsxAttributes<
  {
    items: Item[];
  },
  'nav'
>;

export const HeaderMenuMobile: React.FC<MenuProps> = ({
  items,
  className,
  onClick,
  match,
  language,
  languages,
  ...otherProps
}) => {
  const setParams = ({ externalLink, nodeUrl, targetBlank }) => {
    return {
      onClick,
      width: 'full',
      isLink: !!nodeUrl,
      as: !!nodeUrl ? 'link' : 'a',
      to: nodeUrl ? nodeUrl ?? '/404' : null,
      target: targetBlank ? '_blank' : null,
      href: externalLink,
    };
  };

  const [openedKeys, setOpenedKeys] = useState<boolean>(null);

  const { getOnChange, getChecked } = useChoiceGroup({
    value: openedKeys,
    getKey: item => {
      return item.node?.title || item?.title;
    },
    callBack: params => {
      setOpenedKeys(params.value);
    },
    multiple: true,
  });

  const mainMenuItems = items?.filter(
    item => !item.node?.nodeUrl?.includes('/contact-us')
  );

  const contactUsItem = items?.find(item =>
    item.node?.nodeUrl?.includes('/contact-us')
  );

  const history = useHistory();
  const servicesHandler = async (title: string, nodeUrl: string) => {
    if (!nodeUrl.includes('services')) return;
    const service = title.trim().toLowerCase();
    let dataLayer = {
      dataLayer: {
        event: 'click_services',
        services:
          service === 'services'
            ? 'click_all_services'
            : `click_${service.split(' ').join('_')}`,
      },
    };
    try {
      await TagManager.dataLayer(dataLayer);

      if (nodeUrl.includes('http')) {
        window.location.href = nodeUrl;
      } else {
        history.push(nodeUrl);
      }
    } catch (error) {
      console.error('Error with TagManager dataLayer:', error);
    }
  };

  return (
    <nav className={cnHeaderMenuMobile(null, [className])}>
      <div className={cnHeaderMenuMobile('Main')}>
        <ul className={cnHeaderMenuMobile('List')}>
          {mainMenuItems.map((item, index) => {
            const {
              node: { title, nodeUrl, externalLink, targetBlank },
              nodes,
              columns,
              sideColumn,
              additionalNodes,
            } = item || {};
            const tagWrapperAttrs = setParams({
              externalLink,
              nodeUrl,
              targetBlank,
            });

            return (
              <li
                className={cnHeaderMenuMobile('Item')}
                key={cnHeaderMenuMobile('Item', { index })}
              >
                <div className={cnHeaderMenuMobile('ItemWrapper')}>
                  <Button
                    className={cnHeaderMenuMobile('Link', {})}
                    view="transparent"
                    as="a"
                    label={title}
                    size="s"
                    align="left"
                    {...tagWrapperAttrs}
                    onClick={() => servicesHandler(title, nodeUrl)}
                  />

                  {(nodes?.length > 0 || columns?.length > 0) && (
                    <Button
                      className={cnHeaderMenuMobile('CollapseIcon', {
                        active: getChecked(item),
                      })}
                      view="transparent"
                      size="s"
                      iconRight={IconArrowDown}
                      onClick={e => getOnChange(item)(e)}
                    />
                  )}
                </div>
                {nodes && (
                  <ul
                    className={cnHeaderMenuMobile('SubList', {
                      active: getChecked(item),
                    })}
                  >
                    {nodes.map(
                      (
                        {
                          title,
                          nodeUrl,
                          externalLink,
                          targetBlank,
                          logo,
                          label,
                        },
                        index
                      ) => {
                        const tagWrapperAttrs = setParams({
                          externalLink,
                          targetBlank,
                          nodeUrl,
                        });
                        return (
                          <li
                            className={cnHeaderMenuMobile('SubItem')}
                            key={cnHeaderMenuMobile('SubItem', { index })}
                          >
                            <Button
                              className={cnHeaderMenuMobile('Link', {})}
                              view="text"
                              align="left"
                              size="s"
                              label={title}
                              {...tagWrapperAttrs}
                              exact
                            />
                          </li>
                        );
                      }
                    )}
                  </ul>
                )}
                <ul
                  className={cnHeaderMenuMobile('SubList', {
                    active: getChecked(item),
                  })}
                >
                  {columns &&
                    [...columns, sideColumn]?.map((item, index) => {
                      const { title, nodes } = item || {};
                      return (
                        <li
                          className={cnHeaderMenuMobile('Item')}
                          key={cnHeaderMenuMobile('Item', { index })}
                        >
                          <div className={cnHeaderMenuMobile('ItemWrapper')}>
                            <Button
                              className={cnHeaderMenuMobile('Link', {})}
                              view="transparent"
                              // as="a"
                              label={title}
                              size="s"
                              align="left"
                            />
                            <Button
                              className={cnHeaderMenuMobile('CollapseIcon', {
                                active: getChecked(item),
                              })}
                              view="transparent"
                              size="s"
                              iconRight={IconArrowDown}
                              onClick={e => getOnChange(item)(e)}
                            />
                          </div>
                          <ul
                            className={cnHeaderMenuMobile('SubList', {
                              active: getChecked(item),
                            })}
                          >
                            {nodes.map(
                              (
                                {
                                  title,
                                  nodeUrl,
                                  externalLink,
                                  targetBlank,
                                  logo,
                                  label,
                                },
                                index
                              ) => {
                                const tagWrapperAttrs = setParams({
                                  externalLink,
                                  targetBlank,
                                  nodeUrl,
                                });

                                return (
                                  <li
                                    className={cnHeaderMenuMobile('SubItem')}
                                    key={cnHeaderMenuMobile('SubItem', {
                                      index,
                                    })}
                                  >
                                    <Button
                                      className={cnHeaderMenuMobile('Link', {})}
                                      view="text"
                                      align="left"
                                      size="s"
                                      // label={title}
                                      {...tagWrapperAttrs}
                                      exact
                                      onClick={() =>
                                        servicesHandler(title, nodeUrl)
                                      }
                                    >
                                      {logo && (
                                        <img
                                          src={logo.urlWebp || logo.url}
                                          alt={title}
                                          className="Picture"
                                        />
                                      )}
                                      {title}
                                      {logo && <IconOpenInNew />}
                                    </Button>
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </li>
                      );
                    })}
                  {additionalNodes &&
                    additionalNodes.map(
                      ({
                        title,
                        nodeUrl,
                        externalLink,
                        targetBlank,
                        logo,
                        label,
                      }) => {
                        const tagWrapperAttrs = setParams({
                          externalLink,
                          targetBlank,
                          nodeUrl,
                        });
                        return (
                          <li className={cnHeaderMenuMobile('SubItem')}>
                            <Button
                              className={cnHeaderMenuMobile('Link', {})}
                              view="text"
                              align="left"
                              size="s"
                              label={title}
                              {...tagWrapperAttrs}
                              exact
                              onClick={() => servicesHandler(title, nodeUrl)}
                            />
                          </li>
                        );
                      }
                    )}
                </ul>
              </li>
            );
          })}
        </ul>
        {contactUsItem && (
          <li className={cnHeaderMenuMobile('Cta')}>
            <Button
              // className={cnHeaderMenuMobile('Link', {})}
              label={contactUsItem?.node?.title}
              size="m"
              view="secondary"
              iconRight={IconArrowRight}
              {...setParams({ ...contactUsItem.node })}
              exact
            />
          </li>
        )}
      </div>
      <div className={cnHeaderMenuMobile('Languages')}>
        {languages?.map(lang => {
          const isActive = language.slug === lang.slug;
          let redirectPath = '';
          if (!lang.slug) {
            redirectPath = generatePath(`/${match.path.substring(21)}`, {
              ...match.params,
              lang: lang.slug,
            });
          } else {
            redirectPath = generatePath(match.path, {
              ...match.params,
              lang: lang.slug,
            });
          }
          return (
            <a
              href={redirectPath}
              className={cnHeaderMenuMobile('Languages-Item', {
                Active: isActive,
              })}
              key={lang.slug}
            >
              <Typography.Text
                size="xs"
                weight="bold"
                transform="uppercase"
                onClick={() => localStorage.setItem('currentLang', lang.slug)}
              >
                {lang.slug === '' ? 'EN' : lang.slug.toUpperCase()}
              </Typography.Text>
            </a>
          );
        })}
      </div>
    </nav>
  );
};
