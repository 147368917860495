import './Banner.scss';

import React, { HTMLAttributes } from 'react';
import { cn } from '../../utils/bem';
import { Typography } from '@dataartdev/uikit/Typography';
import { TypographyPropSize } from '@dataartdev/uikit/__internal__/cjs-src/components/Typography/Title/Title';
import { Button, ButtonPropSize } from '@dataartdev/uikit/Button';
import { Picture } from '@dataartdev/uikit/Picture';
import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';
import { IconProps } from '@dataartdev/uikit/Icon';
import { CtaScrollerBtn } from '../CtaScrollerBtn';

export const bannerPropView = ['glassdoor', 'vacancy', 'section'] as const;
export type BannerPropView = typeof bannerPropView[number];
export const bannerPropViewDefault: BannerPropView = bannerPropView[0];

interface Props extends HTMLAttributes<HTMLDivElement> {
  inverted?: boolean;
  bgColor?: string;
  view?: BannerPropView;
  title?: string;
  titleSize?: TypographyPropSize;
  text?: string;
  picture?: any;
  labelButton?: string;
  buttonIcon?: React.FC<IconProps>;
  buttonIconSize?: ButtonPropSize;
  targetBlank?: boolean;
  link?: string;
  onClick?: (e: React.MouseEvent) => void;
  pictureLabel?: string;
  buttonAttrs?: any;
  labelButtonSecondary?: string;
  onClickSecondary?: () => void;
  buttonAttrsSecondary?: any;
  leftColor?: string;
  rightColor?: string;
  subtitle?: string;
  list?: any;
}

const cnBanner = cn('Banner');

export const Banner: React.FC<Props> = props => {
  const {
    view = bannerPropViewDefault,
    title,
    titleSize,
    labelButton,
    text,
    picture,
    pictureLabel,
    buttonIcon = IconArrowRight,
    buttonIconSize = 'm',
    buttonAttrs,
    bgColor,
    inverted,
    className,
    labelButtonSecondary,
    onClickSecondary,
    buttonAttrsSecondary,
    subtitle,
    list,
    // targetBlank,
    // link,
    // onClick,
    // leftColor,
    // rightColor,
  } = props;

  return (
    <div
      className={cnBanner({ view, inverted }, [className])}
      style={{ backgroundColor: bgColor }}
    >
      <div
        className={
          picture ? 'Banner-Inner Banner-Inner_needPicture' : 'Banner-Inner'
        }
      >
        {subtitle && (
          <Typography.Text
            parse
            className={cnBanner('SubTitle')}
            size="xs"
            weight="bold"
            transform="uppercase"
            as="h3"
          >
            {subtitle}
          </Typography.Text>
        )}
        {title && (
          <Typography.Title
            parse
            className={cnBanner('Title')}
            size={titleSize}
            as="h2"
          >
            {title}
          </Typography.Title>
        )}
        <div className={cnBanner('InnerContent')}>
          {text && (
            <Typography.Text className={cnBanner('Text')} parse>
              {text}
            </Typography.Text>
          )}
          {list && (
            <Typography.Text className={cnBanner('List')} parse>
              {list}
            </Typography.Text>
          )}
        </div>
        {Object.keys(buttonAttrs).length > 0 && (
          <div className={cnBanner('Button')}>
            {'isCTA' in buttonAttrs ? (
              <CtaScrollerBtn {...buttonAttrs} view="secondary" />
            ) : (
              <Button
                view="link-secondary"
                iconRight={buttonIcon}
                iconSize={buttonIconSize}
                label={labelButton}
                // onClick={e => {
                //   e.preventDefault();
                //   if (targetBlank) {
                //     window.open(link, '_blank');
                //   } else {
                //     window.location.href = link;
                //   }
                // }}
                inverted={inverted}
                {...buttonAttrs}
              />
            )}
          </div>
        )}
        {labelButtonSecondary && (
          <div className={cnBanner('SecondaryButton')}>
            <Button
              view="link-secondary"
              iconRight={buttonIcon}
              iconSize={buttonIconSize}
              label={labelButtonSecondary}
              onClick={onClickSecondary}
              inverted={inverted}
              {...buttonAttrsSecondary}
            />
          </div>
        )}
      </div>
      {picture && (
        <div className={cnBanner('Picture')}>
          {pictureLabel && (
            <Typography.Text
              parse
              className={cnBanner('Picture-Label')}
              size="xs"
              weight="bold"
              transform="uppercase"
              as="span"
            >
              {pictureLabel}
            </Typography.Text>
          )}
          <Picture {...picture} />
        </div>
      )}
    </div>
  );
};
