// @ts-nocheck
import './PressReleaseCard.scss';

import React from 'react';
import { NavLink } from 'react-router-dom';
import { dictionariesSelector } from 'store';

import { usePropsHandler } from '../EventInterceptor/usePropsHandler';
import { forwardRefWithAs } from '../../utils/types/PropsWithAsAttributes';
import { cn } from '../../utils/bem';

import { Picture } from '@dataartdev/uikit/Picture';
import { Button } from '@dataartdev/uikit/Button';
import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';
import * as Typography from '@dataartdev/uikit/Typography';
import { useSelector } from 'react-redux';

export type Props = {
  routePath?: string;
  picture?: any;
  date?: string;
  location?: string;
  title?: string;
  url?: string;
  path?: string;
  redirectTo?: string;
};

export const cnPressReleaseCard = cn('PressReleaseCard');

export const PressReleaseCard = forwardRefWithAs<Props>((props, ref) => {
  const { ...data } = usePropsHandler(cnPressReleaseCard(), props);

  const { companySiteOffering } = useSelector(dictionariesSelector) || {};

  const { picture, date, location, title, path, redirectTo, fullUrl } =
    data || {};

  return (
    <div className={cnPressReleaseCard()}>
      {path && <NavLink className={cnPressReleaseCard('Link')} to={path} />}

      {(redirectTo || fullUrl) && (
        <a
          href={redirectTo || fullUrl}
          className={cnPressReleaseCard('Link')}
          target="_blank"
          rel="noreferrer noopener"
          aria-hidden="true"
        >
          {' '}
        </a>
      )}

      {picture && (
        <div className={cnPressReleaseCard('Picture')}>
          <Picture {...picture} />
        </div>
      )}

      <div className={cnPressReleaseCard('Inner')}>
        {(date || location) && (
          <div className={cnPressReleaseCard('Top')}>
            {date && (
              <Typography.Typography.Text
                weight="semibold"
                transform="uppercase"
                size="xs"
                as="span"
              >
                {date}
              </Typography.Typography.Text>
            )}

            {location && <div className={cnPressReleaseCard('Slash')} />}

            {location && (
              <Typography.Typography.Text
                weight="semibold"
                transform="uppercase"
                size="xs"
                as="span"
              >
                {location}
              </Typography.Typography.Text>
            )}
          </div>
        )}

        {title && (
          <Typography.Typography.Title
            size="sm"
            as="h3"
            className={cnPressReleaseCard('Title')}
          >
            {title}
          </Typography.Typography.Title>
        )}

        <Button
          className={cnPressReleaseCard('Button')}
          view="link-secondary"
          iconSize={'m'}
          label={`${companySiteOffering.companySiteOfferingSeeMoreTitle ||
            'Read more'}`}
          iconRight={IconArrowRight}
          isLink={!!path}
          to={path}
        />
      </div>
    </div>
  );
});
