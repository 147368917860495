import './WebinarForm.scss';

import React, { useRef, useState } from 'react';
import * as yup from 'yup';
import { Typography } from '@dataartdev/uikit/Typography';
import { Picture } from '@dataartdev/uikit/Picture';

import { useSelector } from 'react-redux';
import { dictionariesSelector, layoutSelector } from 'store';

import { Form, FormBody } from '../../Form';
import { TextField } from '@dataartdev/uikit/TextField';
import { Button } from '@dataartdev/uikit/Button';
import { Checkbox } from '@dataartdev/uikit/Checkbox';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';

import { mutationWebinarForm } from 'network';
import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';

import { cnModalPage } from '../ModalPage';

import TagManager from 'react-gtm-module';
import { recaptchaKey, recaptchaFormKey } from 'common/constants';
import ReCAPTCHA from 'react-google-recaptcha';

import sbjs from 'sourcebuster';
import { usePagePath } from '../../../hooks/usePagePath';

sbjs.init();

type Props = {
  onSubmitForm: (val: any) => void;
  modalData: object;
};

export const WebinarForm: React.FC<Props> = (
  { onSubmitForm, modalData },
  ref
) => {
  const { companySiteForm }: any = useSelector(dictionariesSelector) || {};
  const { companySiteFormWebinar: staticContent } = companySiteForm || {};
  const reCaptchaRef = useRef(null);
  const [successForm, setSuccessForm] = useState<boolean>(false);

  const { pageId }: any = useSelector(layoutSelector) || {};

  const pagePath = usePagePath();

  const cameFrom = `typ: ${sbjs.get.current.typ} | src: ${sbjs.get.current.src} | mdm: ${sbjs.get.current.mdm} | cmp: ${sbjs.get.current.cmp} | cnt: ${sbjs.get.current.cnt} | trm: ${sbjs.get.current.trm}`;
  const submitButtonID = staticContent?.companySiteFormWebinarTrackingID || '';

  const onSubmit = async (values, actions) => {
    const token = await reCaptchaRef.current.executeAsync();

    await mutationWebinarForm({
      body: {
        ...values,
        pageId,
        [`${recaptchaFormKey}`]: token,
        cameFrom: cameFrom,
        trackingId: submitButtonID,
      },
    }).then(() => {
      onSubmitForm(true);
      setSuccessForm(true);
      actions.resetForm();

      TagManager.dataLayer({
        dataLayer: {
          event: 'send_form',
          form_name: 'subscribe_form',
          page_path: pagePath,
          label: values.email,
          tags: 'not set',
        },
      });
    });
  };

  const schema = yup.object({
    email: yup
      .string()
      .email(
        staticContent.companySiteFormWebinarEmail
          .companySiteFormWebinarEmailError
      )
      .required(staticContent.companySiteFormWebinarRequiredField)
      .nullable(),
    name: yup
      .string()
      .min(3)
      .nullable(),
    company: yup
      .string()
      .min(3)
      .nullable(),
    privacyAgreement: yup
      .boolean()
      .oneOf([true], 'on')
      .required(),
  });

  return (
    <div className={cnModalPage({ webinar: true })}>
      <Typography.Text
        size="xs"
        weight="bold"
        transform="uppercase"
        className={cnModalPage('Title')}
      >
        {modalData.title}
      </Typography.Text>
      <Grid
        breakpoints={{
          desktop: { cols: 12, gap: '6xl' },
          tablet: { cols: 1, gap: 'xl' },
          mobile: { cols: 1, gap: 'xl' },
        }}
      >
        <GridItem breakpoints={{ desktop: { col: 5 } }}>
          <Typography.Title size="md" className={cnModalPage('Title')}>
            {modalData.subtitle}
          </Typography.Title>
          <Typography.Text className={cnModalPage('Text')}>
            {modalData.text}
          </Typography.Text>
          {modalData.picture && <Picture {...modalData.picture} />}
        </GridItem>
        <GridItem breakpoints={{ desktop: { col: 7 } }}>
          <div className={cnModalPage('Body')}>
            <Form
              enableReinitialize
              initialValues={{
                email: '',
                name: '',
                company: '',
                privacyAgreement: false,
              }}
              validationSchema={schema}
              onSubmit={onSubmit}
            >
              {({
                isSubmitting,
                errors,
                handleChange,
                handleBlur,
                values,
                touched,
                dirty,
              }) => {
                return (
                  <FormBody>
                    <Grid
                      breakpoints={{
                        desktop: { cols: 12, gap: 'm' },
                        tablet: { cols: 8, gap: 'm' },
                        mobile: { cols: 1, gap: 'm' },
                      }}
                    >
                      <GridItem
                        breakpoints={{
                          desktop: { col: 12 },
                          tablet: { col: 8 },
                        }}
                      >
                        <TextField
                          name="name"
                          onChange={({ e }) => handleChange(e)}
                          onBlur={handleBlur}
                          value={values.name}
                          label={staticContent.companySiteFormWebinarName}
                          view="outline"
                          width="full"
                          caption={touched.name && errors.name}
                          status={touched.name && !!errors.name && 'alert'}
                        />
                      </GridItem>
                      <GridItem
                        breakpoints={{
                          desktop: { col: 12 },
                          tablet: { col: 8 },
                        }}
                      >
                        <TextField
                          name="email"
                          onChange={({ e }) => handleChange(e)}
                          onBlur={handleBlur}
                          value={values.email}
                          label="E-mail"
                          view="outline"
                          width="full"
                          required
                          caption={touched.email && errors.email}
                          status={touched.email && !!errors.email && 'alert'}
                        />
                      </GridItem>
                      <GridItem
                        breakpoints={{
                          desktop: { col: 12 },
                          tablet: { col: 8 },
                        }}
                      >
                        <TextField
                          name="company"
                          onChange={({ e }) => handleChange(e)}
                          onBlur={handleBlur}
                          value={values.company}
                          label={staticContent.companySiteFormWebinarCompany}
                          view="outline"
                          width="full"
                          caption={touched.company && errors.company}
                          status={
                            touched.company && !!errors.company && 'alert'
                          }
                        />
                      </GridItem>
                      <GridItem
                        breakpoints={{
                          desktop: { col: 12 },
                          tablet: { col: 8 },
                        }}
                        className={cnModalPage('Bottom')}
                      >
                        <Checkbox
                          required
                          label={
                            staticContent.companySiteFormWebinarSubscribePolicy
                          }
                          onChange={({ e }) => handleChange(e)}
                          onBlur={handleBlur}
                          name="privacyAgreement"
                          checked={values.privacyAgreement}
                        />
                        <div className={cnModalPage('Button')}>
                          <Button
                            id={submitButtonID}
                            iconRight={IconArrowRight}
                            disabled={
                              isSubmitting ||
                              !dirty ||
                              !!Object.keys(errors).length
                            }
                            label={staticContent.companySiteFormWebinarButton}
                            type="submit"
                          />
                          <ReCAPTCHA
                            ref={reCaptchaRef}
                            sitekey={recaptchaKey}
                            size="invisible"
                          />
                        </div>
                      </GridItem>
                    </Grid>
                  </FormBody>
                );
              }}
            </Form>
          </div>
        </GridItem>
      </Grid>
      {successForm && (
        <div className={cnModalPage('Success')}>
          <Typography.Title size="md" className={cnModalPage('Title')}>
            {staticContent.companySiteFormWebinarSuccessTitle}
          </Typography.Title>
          <Typography.Text className={cnModalPage('SubTitle')}>
            {staticContent.companySiteFormWebinarSuccessText}
          </Typography.Text>
        </div>
      )}
    </div>
  );
};
