import './MainHeader.scss';

import React, { useState, useEffect, useRef } from 'react';
import { generatePath, RouteComponentProps } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  menuSelector,
  dictionariesSelector,
  languagesSelector,
  searchModalVisibleSelector,
  setSearchModalVisible,
  contactButtonVisibleSelector,
  headerTransparentSelector,
  setContactButtonVisible,
} from 'store';

import { PATH } from 'router';
import { cn } from 'ui/utils/bem';
import { Button } from '@dataartdev/uikit/Button';
import { Modal } from '@dataartdev/uikit/Modal';
import { Tooltip } from '@dataartdev/uikit/Tooltip';
import { IconBurger } from '@dataartdev/uikit/IconBurger';
import { IconClose } from '@dataartdev/uikit/IconClose';
import { Mq } from '@dataartdev/uikit/Mq';
import { Combobox } from '@dataartdev/uikit/Combobox';
import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';
import { SearchForm } from './SearchForm';
import {
  Header,
  HeaderModule,
  HeaderLogo,
  HeaderMenu,
  HeaderMenuMobile,
} from '../../components/Header/Header';

import { useIntl } from 'react-intl';
import parse from 'html-react-parser';
import { useLazyGetAllTagsQuery } from '../../../network/api/searchPage';
import { isDesktopSelector } from '../../../store';

const cnHeaderWrapper = cn('HeaderWrapper');

type Props = RouteComponentProps;

export const MainHeader: React.FC<Props> = ({ history, match, location }) => {
  const [modifier, setModifier] = useState('');
  const menuItems = useSelector(menuSelector) || [];
  const [isHovered, setIsHovered] = useState(false);
  const [scrolling, setScrolling] = useState(false);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [isLangPopupNeeded, setIsLangPopupNeeded] = useState(false);
  const [isLangPopupStartHide, setIsLangPopupStartHide] = useState(false);
  const [showBanner, setShowBanner] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { locale } = useIntl();

  const headerTransparent = useSelector(headerTransparentSelector);
  useEffect(() => {
    switch (headerTransparent) {
      case 'Transparent':
        setModifier('transparent');
        break;
      case 'Transparent & Inverted':
        setModifier('transparentInverted');
        break;
      default:
        setModifier('');
    }
    if (isHovered) {
      setModifier('hovered');
    }
    if (scrolling) {
      setModifier('scrolled');
    }
    if (isSearchActive) {
      setModifier('search-opened');
    }
    if (isModalOpen) {
      setModifier('mobile-menu-opened');
    }
  }, [headerTransparent, isHovered, scrolling, isModalOpen, isSearchActive]);

  const { isVisible, isInitiallyChecked } =
    useSelector(contactButtonVisibleSelector) || {};

  useEffect(() => {
    dispatch(
      setContactButtonVisible({ isVisible: false, isInitiallyChecked: true })
    );
  }, [location]);

  const contactUs = menuItems?.find(item =>
    item.node?.nodeUrl?.includes('/contact-us')
  );
  const mainMenuItems = menuItems?.filter(
    item => !item.node?.nodeUrl?.includes('/contact-us')
  );

  const language = window.INITIAL_STATE.languages.find(
    w => w.slug === localStorage.getItem('currentLang')
  );

  const { companySiteHome: staticContent } =
    useSelector(dictionariesSelector) || {};
  const isDesktop = useSelector(isDesktopSelector);

  const availableTranslations = useSelector(languagesSelector) || [];

  const languages = window.INITIAL_STATE.languages.filter(w =>
    availableTranslations.includes(w.iso.toLowerCase())
  );

  const bubbleText =
    staticContent?.companySiteHomeDifferentLanguagesBubble || `bubble text`;

  const [fetchTags, { data: tagsData }] = useLazyGetAllTagsQuery();

  const dispatch = useDispatch();

  useEffect(() => {
    if (isSearchActive) {
      fetchTags();
    }
    dispatch(setSearchModalVisible(isSearchActive));
  }, [isSearchActive, dispatch, fetchTags]);
  const isSearchFormVisible = useSelector(searchModalVisibleSelector);

  const searchButtonRef = useRef();

  const isSearchHandler = () => {
    setIsSearchActive((state: boolean) => !state);
  };

  const reloadMainLayout = () => {
    if (locale === 'en') {
      history.push('/');
    } else {
      history.push(generatePath(PATH.MAIN, { lang: locale }));
    }
  };

  const mouseEnterHandler = () => {
    setIsHovered(true);
  };

  const mouseLeaveHandler = () => {
    setIsHovered(false);
  };

  const onHeaderMenuHandler = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    const onScrollHandler = (): void => {
      if (window.scrollY > 20) {
        setScrolling(true);
      } else if (window.scrollY <= 20) {
        setScrolling(false);
      }

      if (window.scrollY > 80) {
        setShowBanner(true);
      } else if (window.scrollY <= 80) {
        setShowBanner(false);
      }
    };

    window.addEventListener('scroll', onScrollHandler);
    return () => window.removeEventListener('scroll', onScrollHandler);
  });

  useEffect(() => {
    setIsModalOpen(false);
  }, [history.location.pathname]);

  useEffect(() => {
    if (localStorage.getItem('needLangPopup') !== null) {
      return;
    }
    localStorage.setItem('needLangPopup', 'true');
    setIsLangPopupNeeded(true);

    const onTimeoutPopupFunction = setTimeout(() => {
      setIsLangPopupStartHide(true);
    }, 5000);

    return () => clearTimeout(onTimeoutPopupFunction);
  }, []);

  useEffect(() => {
    const onSetPopupStateHandler = () => {
      setIsLangPopupNeeded(false);
    };
    document.addEventListener('click', onSetPopupStateHandler);

    return () => document.removeEventListener('click', onSetPopupStateHandler);
  }, []);

  useEffect(() => {
    const onMouseEnterHandler = () => {
      setIsLangPopupNeeded(false);
    };
    document
      .querySelector('.Header')
      ?.addEventListener('mouseenter', onMouseEnterHandler);
    return () =>
      document
        .querySelector('.Header')
        ?.removeEventListener('mouseenter', onMouseEnterHandler);
  }, []);

  const { announcement } = window.INITIAL_STATE;
  // const hideBanner = Object.values(announcement).every(value => !!value);

  const langToolTip = (
    <Tooltip
      className={isLangPopupStartHide ? 'Tooltip-Hidden' : ''}
      view="secondary"
      staticPosition
      directionPosition="downStartRight"
      size="l"
      secondLine={parse(
        bubbleText ||
          'You are opening our <b>English language</b> website. You can keep reading or switch to other languages.'
      )}
    ></Tooltip>
  );

  return (
    <div className={cnHeaderWrapper()}>
      <Header
        modifier={modifier}
        leftSide={
          <>
            <HeaderModule>
              <HeaderLogo onClick={reloadMainLayout} />
            </HeaderModule>
          </>
        }
        rightSide={
          <>
            {menuItems?.length > 0 && (
              <Mq query="--desktop">
                <HeaderModule>
                  <HeaderMenu
                    items={mainMenuItems}
                    hoverState={isHovered}
                    onHover={mouseEnterHandler}
                    onBlur={mouseLeaveHandler}
                    onHeaderMenuHandler={onHeaderMenuHandler}
                  />
                </HeaderModule>
              </Mq>
            )}
            <Mq query="--desktop">
              <div className="Header-Push">
                <Button
                  className={
                    'Header-Contact ' +
                    (!isVisible && isInitiallyChecked ? 'visible' : 'hidden')
                  }
                  view="secondary"
                  isLink
                  to={contactUs?.node?.nodeUrl}
                  iconRight={IconArrowRight}
                  label={contactUs?.node?.title}
                />
                {languages.length > 1 && (
                  <div className="Header-Languages">
                    <Combobox
                      view="transparent"
                      dropdownView="transparent"
                      items={languages.filter(w => w.iso !== language?.iso)}
                      getItemLabel={item => item.title}
                      value={language}
                      onChange={({ value }) => {
                        localStorage.setItem('currentLang', value.slug);
                        if (!value.slug) {
                          window.location = generatePath(
                            `/${match.path.substring(21)}`,
                            {
                              ...match.params,
                              lang: value.slug,
                            }
                          );
                        }
                        window.location = generatePath(match.path, {
                          ...match.params,
                          lang: value.slug,
                        });
                      }}
                    />
                    {isLangPopupNeeded && langToolTip}
                  </div>
                )}
              </div>
            </Mq>
            <button
              onClick={isSearchHandler}
              ref={searchButtonRef}
              className={cnHeaderWrapper('Search', {
                active: isSearchFormVisible,
              })}
            >
              <svg
                width="24"
                height="24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M11 4a7 7 0 0 1 5.6 11.201l.054.043.053.049 3 3a1 1 0 0 1-1.32 1.497l-.094-.083-3-3a1.007 1.007 0 0 1-.094-.108A7 7 0 1 1 11 4Zm0 2a5 5 0 1 0 0 10 5 5 0 0 0 0-10Z"
                  fill="#0F161A"
                />
              </svg>
              <svg width="10" height="10" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="m6.414 5 3.293-3.293A.999.999 0 1 0 8.293.293L5 3.586 1.707.293A.999.999 0 1 0 .293 1.707L3.586 5 .293 8.293a.999.999 0 1 0 1.414 1.414L5 6.414l3.293 3.293a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414L6.414 5Z"
                  fill="#FFF"
                  fillRule="evenodd"
                />
              </svg>
            </button>
            <Mq query="--tablet-max">
              <HeaderModule className="Header-Burger">
                <Button
                  view="ghost"
                  iconRight={isModalOpen ? IconClose : IconBurger}
                  onClick={
                    !isModalOpen
                      ? (): void => setIsModalOpen(!isModalOpen)
                      : null
                  }
                />
                {isLangPopupNeeded && langToolTip}
              </HeaderModule>
            </Mq>
          </>
        }
      />
      {isSearchFormVisible && (
        <div className={cnHeaderWrapper('Form', { showBanner: !showBanner })}>
          <SearchForm tagsData={tagsData} onClickHandler={isSearchHandler} />
        </div>
      )}
      {!isDesktop && (
        <Modal
          isOpen={isModalOpen}
          className={cnHeaderWrapper('Modal')}
          hasOverlay
          width="full"
          onOverlayClick={(): void => setIsModalOpen(false)}
          rootClassName={'MobileMenu'}
          position="right"
          visibleHeader
          onOpen={() => {
            document.body.style.overflow = 'hidden';
          }}
          onClose={() => {
            document.body.style.overflow = 'unset';
          }}
          onEsc={(): void => {
            setIsModalOpen(false);
            document.body.style.overflow = 'unset';
          }}
        >
          <HeaderMenuMobile
            items={menuItems}
            language={language}
            languages={languages}
            match={match}
            onClick={(): void => setIsModalOpen(false)}
          />
        </Modal>
      )}
    </div>
  );
};
