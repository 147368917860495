// @ts-nocheck
/* eslint-disable jsx-a11y/anchor-has-content */
import './VideoCard.scss';

import React, { useMemo, useState } from 'react';

import { usePropsHandler } from '../EventInterceptor/usePropsHandler';
import { cn } from '../../utils/bem';

import { IconClose } from '@dataartdev/uikit/IconClose';
import { IconPlay } from '@dataartdev/uikit/IconPlay';
import { IconYouTube } from '@dataartdev/uikit/IconYouTube';

import { getSizeByMap } from '@dataartdev/uikit/__internal__/cjs-src/utils/getSizeByMap';
import { Button } from '@dataartdev/uikit/Button';
import { Modal } from '@dataartdev/uikit/Modal';
import { Picture } from '@dataartdev/uikit/Picture';
import { Tag } from '@dataartdev/uikit/Tag';
import { Typography } from '@dataartdev/uikit/Typography';

export const cardPropSize = ['s', 'l'] as const;
export type CardPropSize = typeof cardPropSize[number];
export const cardPropSizeDefault: CardPropSize = cardPropSize[0];

export const cardPropView = ['feed', 'filled'] as const;
export type CardPropView = typeof cardPropView[number];
export const cardPropViewDefault: CardPropView = cardPropView[0];

export const cardPropDirection = ['column', 'row'] as const;
export type CardPropDirection = typeof cardPropDirection[number];
export const cardPropSizeDirection: CardPropDirection = cardPropDirection[0];

export type Props = {
  size?: CardPropSize;
  view?: CardPropView;
  direction?: CardPropDirection;
};

const sizeMap: Record<Props> = {
  s: 'tiny',
  m: 'sm',
  l: 'md',
};

export const cnVideoCard = cn('VideoCard');

export const VideoCard: React.FC<Props> = props => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    view = cardPropViewDefault,
    direction = cardPropSizeDirection,
    size = cardPropSizeDefault,
    ...data
  } = usePropsHandler(cnVideoCard(), props);

  const {
    postDate,
    hrvTags,
    tags: tagsList,
    title,
    bottomDescription,
    picture,
    linkIcon = IconYouTube,
    onClickButton,
    url,
    videoUrl,
    descriptionSmall,
    iconSize = 'm',
    style,
    linkTitle,
    buttonSize,
    buttonIconSize,
    detailLinkTitle,
  } = data || {};

  let currentIcon = linkIcon;

  const tags = useMemo(
    () => (
      <>
        <div className={cnVideoCard('Tags')}>
          {hrvTags && (
            <>
              {hrvTags.map(tag => (
                <div key={tag.id} className={cnVideoCard('Tag')}>
                  <Tag mode="link" label={tag.title} size="s" />
                </div>
              ))}
            </>
          )}
          {tagsList && (
            <>
              {tagsList.map(tag => (
                <div key={tag.id} className={cnVideoCard('Tag')}>
                  <Tag mode="link" label={tag.title} size="s" />
                </div>
              ))}
            </>
          )}
        </div>
      </>
    ),
    [hrvTags, tagsList]
  );

  const onClickButtonHandler = () => {
    if (url) {
      window.open(url, '_blank');
    }
  };

  return (
    <>
      <div
        className={cnVideoCard({
          view,
          size,
          direction,
        })}
        style={style}
      >
        {videoUrl && (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <div
            className={cnVideoCard('Link')}
            onClick={() => setIsModalOpen(true)}
          />
        )}

        {view !== 'text' && view !== 'text2' && picture && (
          <div className={cnVideoCard('Photo')}>
            <Button
              view="primary"
              form="circle"
              iconRight={IconPlay}
              size={buttonSize}
              iconSize={buttonIconSize}
              onlyIcon
              className={cnVideoCard('Photo-Button')}
              onClick={() => setIsModalOpen(true)}
            />
            <Picture {...picture} />
          </div>
        )}

        {(postDate || descriptionSmall || tagsList || title || linkTitle) && (
          <div className={cnVideoCard('Body')}>
            {view !== 'text' && view !== 'filled' && postDate && (
              <div className={cnVideoCard('Date')}>
                <Typography.Text weight="semibold" size="xs">
                  {postDate}
                </Typography.Text>
              </div>
            )}

            {descriptionSmall && (
              <div className={cnVideoCard('DescriptionSmall')}>
                <Typography.Text
                  weight="semibold"
                  size="xs"
                  transform="uppercase"
                >
                  {descriptionSmall}
                </Typography.Text>
              </div>
            )}

            {view === 'text3' && tags}

            {title && (
              <div className={cnVideoCard('Title')}>
                <Typography.Title
                  weight="bold"
                  size={getSizeByMap(sizeMap, size)}
                  as="h3"
                >
                  {title}
                </Typography.Title>
              </div>
            )}

            {view !== 'text' && view !== 'text3' && view !== 'filled' && tags}

            {view !== 'text' &&
              view !== 'text2' &&
              (linkTitle || detailLinkTitle) && (
                <div className={cnVideoCard('Button')}>
                  <Button
                    view="link-secondary"
                    iconRight={currentIcon}
                    iconSize={iconSize}
                    label={detailLinkTitle ?? linkTitle}
                    onClick={onClickButton || onClickButtonHandler}
                  />
                </div>
              )}
          </div>
        )}

        {bottomDescription && (
          <Typography.Text
            size="s"
            view="web"
            className={cnVideoCard('BottomDescription')}
          >
            {bottomDescription}
          </Typography.Text>
        )}
      </div>
      <Modal
        className={cnVideoCard('Modal')}
        isOpen={isModalOpen}
        hasOverlay
        width="full"
        onOverlayClick={(): void => setIsModalOpen(false)}
        onEsc={(): void => setIsModalOpen(false)}
      >
        <iframe
          title="modal-iframe"
          src={videoUrl}
          frameBorder="0"
          width="100%"
          height="100%"
        />

        <Button
          view="ghost"
          iconRight={IconClose}
          className="Modal-Close"
          onClick={(): void => setIsModalOpen(false)}
        />
      </Modal>
    </>
  );
};
