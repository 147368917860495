import { useEffect, useRef } from 'react';
import TagManager from 'react-gtm-module';
import { clearFalsyProps } from '../utils';
import { usePagePath } from './usePagePath';
import { Tag } from '../../features/clients/pages/models/client_page';

export const useGTMDataSend = (
  metaTitle = '',
  stringTags: string | Tag[]
): void => {
  const timeOnPageRef = useRef(0);
  const hasSent30sEventRef = useRef(false);
  const hasSentScrollEventRef = useRef(false);
  const engagementEventSentRef = useRef(false);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const tagsRef = useRef<string | null>(null);
  const pagePath = usePagePath();

  useEffect(() => {
    let tags: string | null;
    if (Array.isArray(stringTags) && stringTags.length !== 0) {
      tags = stringTags
        .map(i => i.title)
        .join(', ')
        .trim();
    } else if (typeof stringTags === 'string') {
      tags = stringTags.trim();
    } else tags = null;
    tagsRef.current = tags;

    const sendCombinedAnalyticsEvent = (): void => {
      if (
        (hasSent30sEventRef.current || hasSentScrollEventRef.current) &&
        !engagementEventSentRef.current
      ) {
        const dataLayerBody = clearFalsyProps({
          event: 'engagement_30sec_50%',
          page_path: pagePath,
          page_title: metaTitle,
          tags: tagsRef.current,
        });
        TagManager.dataLayer({
          dataLayer: dataLayerBody,
        });
        engagementEventSentRef.current = true;
      }
    };

    const startTimer = (): void => {
      timerRef.current = setInterval(() => {
        timeOnPageRef.current += 1;
        if (timeOnPageRef.current >= 30 && !hasSent30sEventRef.current) {
          hasSent30sEventRef.current = true;
          sendCombinedAnalyticsEvent();
        }
      }, 1000);
    };

    const stopTimer = (): void => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
        timerRef.current = null;
      }
    };

    // isVisible tab listener
    const handleVisibilityChange = (): void => {
      if (document.visibilityState === 'hidden') {
        stopTimer();
      } else {
        startTimer();
      }
    };

    // Scroll listener
    const handleScroll = (): void => {
      const totalHeight = document.body.offsetHeight;
      const scrolled = window.scrollY;
      const viewportHeight = window.innerHeight;
      const threshold = totalHeight / 2;
      if (
        scrolled + viewportHeight / 2 > threshold &&
        !hasSentScrollEventRef.current
      ) {
        hasSentScrollEventRef.current = true;
        sendCombinedAnalyticsEvent();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('scroll', handleScroll, { passive: true });
    startTimer();

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('scroll', handleScroll);
      stopTimer();
    };
  }, [pagePath, metaTitle, stringTags]);
};
