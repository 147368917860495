// @ts-nocheck
import React from 'react';

import { forwardRefWithAs } from '../../utils/types/PropsWithAsAttributes';
import { BlogCard } from '../BlogCard/BlogCard';
import { DefaultCard } from '../DefaultCard/DefaultCard';
import { EventCard } from '../EventCard/EventCard';
import { ExternalCard } from '../ExternalCard/ExternalCard';
import { TestimonialCard } from '../TestimonialCard/TestimonialCard';
import { VideoCard } from '../VideoCard/VideoCard';
import { PressReleaseCard } from '../PressReleaseCard/PressReleaseCard';
import { CaseCard } from '../CaseCard/CaseCard';

type Props = {
  items?: any;
  getLabel?: (item: any) => string;
  getPath?: (item: any) => string;
};

// Post = 0,
// ExternalPost = 1,
// Video = 2,
// Event = 3,
// Landing = 4,
// News = 5,
// Offering = 6,
// Testimonial = 7

export const EssenceCard = forwardRefWithAs<Props>((props, ref) => {
  const { contentType } = props;

  switch (contentType) {
    case 0:
      return <BlogCard {...props} />;

    case 1:
      return <ExternalCard {...props} />;

    case 2:
      return <VideoCard {...props} />;

    case 3:
      return <EventCard {...props} />;

    case 4:
    case 5:
    case 6:
      return <DefaultCard {...props} />;

    case 7:
      return <TestimonialCard {...props} />;

    case 8:
    case 9:
      return <PressReleaseCard {...props} />;

    case 10:
      return <CaseCard {...props} />;

    default:
      return 'none contentType in essencecard';
  }
});
